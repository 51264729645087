<template>
  <component
    :is="renderComponent"
    ref="componentRef"
    v-bind="{
      ...props,
      scrollWrapClass,
      scrollItemClass,
    }"
    @goScroll="goScroll"
    @custom-event="(v) => emits('custom-event', v)"
  >
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </component>
</template>

<script setup lang="ts">
import { ref, provide, computed, onMounted } from 'vue'
import DrawerPersonalCenter from './components/DrawerPersonalCenter.vue'
import DrawerCheckout from './components/DrawerCheckout.vue'
import { E_SCENE, type C_GiftCard, TScene, TTerminal } from '../../types'
import useApis from '../../hooks/useApis'
import { useAnalysis } from '../../common/analysisSource'
import { getMultLangMap } from '../../common/language'

const { getLangApi } = useApis()
const analysisInstance = useAnalysis()

const props = withDefaults(defineProps<{
  scene: TScene,
  terminal: TTerminal
  drawerProps: Record<string, any>
  cardData: C_GiftCard.CardData
  extraData: Record<string, any>
  language?: Record<string, string> // 为兼容 H5 场景,后续可优化
}>(), {})

const emits = defineEmits(['custom-event'])

const _language = ref()
const componentRef = ref()
const scrollWrapClass = ref('bsc-gc-card-scroll-wrap')
const scrollItemClass = ref('bsc-gc-card-scroll')

const getLanguage = computed(() => _language.value)
const getCardData = computed(() => props.cardData)
const getScene = computed(() => props.scene)

const renderComponent = computed(() => {
switch (props.scene) {
  case E_SCENE.PersonalCenter:
    return DrawerPersonalCenter
  case E_SCENE.Checkout:
    return DrawerCheckout
  default:
    return DrawerPersonalCenter || null
  }
})

const goScroll = (num: number) => {
  const itemWrap = document.getElementsByClassName(scrollWrapClass.value)?.[0] as HTMLElement
  const item = document.getElementsByClassName(scrollItemClass.value)?.[num] as HTMLElement
  if (item && itemWrap) {
    itemWrap.scrollTo({
      top: num === 0 ? 0 :item.getBoundingClientRect().top - itemWrap.getBoundingClientRect().top + itemWrap.scrollTop,
      behavior: 'smooth',
    })
  }
}

const goTab = (index: number) => {
  componentRef.value?.$refs?.tabListRef?.handleTabIndex(index)
}

onMounted(() => {
  const langMap = getMultLangMap({
    scene: props.scene,
    template: props.terminal,
    language: props.language, // 如果存在外部传入的多语言, 则用外部的, 否则走本地获取, 目前只有 H5 用到
  })
  // 为兼容 H5 暂时写的逻辑, H5 改下沉组件获取多语言后可删除
  if (props.language) {
    _language.value = langMap
  } else if (langMap) {
    getLangApi(langMap).then(res => {
      _language.value = Object.assign({}, res, props?.extraData?.bindcardLanguage)
      // extraData.bindcardLanguage 绑卡下沉组件不支持多语言请求,暂时用这个方案,外部传入
      // console.log('🐬🐬🐬 es: language', res, _language.value)
    })
  }
})

provide('analysisInstance', analysisInstance)
provide('language', getLanguage) // 多语言
provide('cardData', getCardData) // 列表数据
provide('scene', getScene) // 场景

defineExpose({
  goScroll,
  goTab,
})
</script>

