<template>
  <div class="bsc-gc-checkout">
    <SDrawer
      v-expose="{
        id: 'expose_giftcard_pop:simple',
      }"
      class="bsc-gc-checkout__drawer"
      append-to-body
      v-bind="props.drawerProps"
      @close-from-icon="onClose"
      @close-from-mask="onClose"
    >
      <template #title>
        <div
          class="bsc-gc-checkout__header"
          @click="() => emits('goScroll', 0)">
          <span class="title">{{ language?.SHEIN_KEY_PWA_24759 }}</span>
          <span class="subtitle">{{ language?.SHEIN_KEY_PWA_35098 }}</span>
        </div>
      </template>

      <TabList
        ref="tabListRef"
        @updateTabIndex="updateTabIndex"
        @updateCardList="updateCardList"
      >
        <div
          v-if="isEmptyAvailableList"
          class="bsc-gc-checkout__subtitle"
        >
          {{ language?.SHEIN_KEY_PWA_35100 }}
        </div>
        <BBindGiftCard
          v-if="getBindCardVisible"
          class="bsc-gc-checkout__bind-card"
          ref="bindGiftCardRef"
          terminal-type="pwa"
          :language="language"
          sence="checkout"
          :isShowSwitch="isShowSwitch"
          :isShowStepText="false"
          :linkBtnTxt="language?.SHEIN_KEY_PWA_28846"
          :is-need-drawer="!isEmptyAvailableList"
          :visible="getBindCardVisible"
          @bind-card-cb="bindCardSubmit"
          @handle-close="() => bindCardVisible = false"
        />
      </TabList>
      <div class="bsc-gc-checkout__bind">
        <div
          v-if="!(isEmptyCard && isAvailableCard)"
          v-tap="{
            id: 'click_newgiftcard_entrance:simple',
            data: {
              tab_type: isAvailableCard ? 'available' : 'unavailable'
            }
          }"
          class="bar"
          @click="bindCardVisible = true"
        >
          <span>
            {{ language?.SHEIN_KEY_PWA_35100 }}
          </span>
          <Icon
            name="sui_icon_more_right_16px"
            size="16px"
            color="#959595"
            :is-rotate="GB_cssRight"
          />
        </div>
      </div>

      <div
        v-if="cardList.length > 0"
        class="bsc-gc-checkout__card"
        :style="{
          height: `calc(100% - ${props.extraData?.discountPriceHeight || 0})` // 解决 pwa 下单页价格未展示全问题
        }"
      >
        <div
          :class="['block', props.scrollWrapClass]"
        >
          <Card
            v-for="(item, index) in cardList"
            :key="`${item.card_no}${item.card_status_tip}${index}`"
            v-tap="{
              id: 'click_listgiftcard:simple',
              data: {
                sequence: index,
                is_available: isAvailableCard ? 1 : 0,
                is_linked: !!item?.bind_card_tip ? 1 : 0
              }
            }"
            v-expose="{
              id: 'expose_listgiftcard:simple',
              data: {
                sequence: index,
                is_available: isAvailableCard ? 1 : 0,
                is_linked: !!item?.bind_card_tip ? 1 : 0
              }
            }"
            :enableCopy="false"
            :isSelect="item.card_no === props?.extraData?.selectedNo"
            :enableGoDetail="false"
            :readonly="!isAvailableCard"
            :class="['card', props.scrollItemClass]"
            :item="item"
            cardHeight="3.3333rem"
            @click="() => onSelectedCard(item)"
          />
        </div>

        <div
          v-if="!!extraData?.discountPrice && isAvailableCard"
          class="bsc-gc-checkout__discount"
        >
          <span class="label">
            {{ language?.SHEIN_KEY_PWA_24759 }}
          </span>
          <span class="price">
            -{{ extraData.discountPrice }}
          </span>
        </div>
      </div>
      <SFeedback
        v-if="!isAvailableCard && isEmptyCard"
        class="bsc-gc-checkout__feedback"
        :content="isAvailableCard ? language?.SHEIN_KEY_PWA_35143 : language?.SHEIN_KEY_PWA_35144"
      />
      <template #footer>
        <slot name="footer"></slot>
      </template>
    </SDrawer>
  </div>
</template>
<script setup lang="ts">
import { inject, ref, computed, type Ref } from 'vue'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SFeedback } from '@shein-aidc/sui-feedback/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import BBindGiftCard from '@shein-aidc/bs-bind-gift-card-mobile'
// import BBindGiftCard from '../../../../bind-gift-card/mobile/src/index'
import Card from './Card.vue'
import TabList from './TabList.vue'
import { E_SCENE, type C_GiftCard, TScene } from '../../../types/index.ts'

const cardList = ref<C_GiftCard.CardItem[]>([])
const tabIndex = ref<number>(0)
const bindCardVisible = ref(false)

const { GB_cssRight } = gbCommonInfo

const props = defineProps<{
  drawerProps: Record<string, string>
  scrollWrapClass: string
  scrollItemClass: string,
  extraData?: Record<string, any>
}>()

const emits = defineEmits(['goScroll', 'custom-event'])
const language: C_GiftCard.language= inject('language')!
const scene: Ref<TScene> = inject('scene')!
const {triggerNotice } = inject('analysisInstance') as any

/**----- computed -----**/
const isAvailableCard = computed(() => tabIndex.value === 0)
const isEmptyCard = computed(() => cardList.value?.length === 0)
const isShowSwitch = computed(() => +props?.extraData?.abtType === 2)
// 没有卡片,且在不可用列表
const isEmptyAvailableList = computed(() => isEmptyCard.value && isAvailableCard.value)
const getBindCardVisible = computed(() => (isEmptyCard.value && isAvailableCard.value) ? true : bindCardVisible.value)

/**----- methods -----**/
const updateCardList = (list) => {
  cardList.value = list
}

const updateTabIndex = (index: number) => {
  tabIndex.value = index
}

const onClose = () => {
  emits('custom-event', { key: 'close' })
}

const onSelectedCard = (item) => {
  emits('custom-event',
  {
    key: 'select',
    data: {
      item: item.card_no === props?.extraData?.selectedNo ? null : item,
    },
  })
}

const bindCardSubmit = (v) => {
  const { resData: { code } = {}, isBindCard } = v
  if (scene.value === E_SCENE.Checkout && isBindCard) {
    const isSuccess = [0].includes(+code)
    const extraData = {
      result: isSuccess ? 1 : 0,
    }
    if (!isSuccess) {
      extraData['result_reason'] = code
    }
    triggerNotice?.({
      id: 'click_card_confirm_result:simple',
      extraData,
    })
  }
  emits('custom-event', {
    key: 'bind-card',
    data: v,
  })
}

defineExpose({
  closeDrawer: () => bindCardVisible.value = false,
})



</script>

<style lang="less">
.bsc-gc-checkout {
  &__drawer {
    overflow: hidden;
    .sui-drawer__body {
      overflow: hidden;
    }
    .sui-drawer__header {
      min-height: auto;
    }
  }
  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: .24rem 0 .2133rem;
    .title {
      color: #767676;
      text-align: center;
      font-family: "SF Pro";
      font-size: .3733rem;
      font-style: normal;
      font-weight: 590;
      line-height: normal;
    }
    .subtitle {
      color: #959595;
      text-align: center;
      font-family: "SF Pro";
      font-size: .2667rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 0 .32rem;
    }
  }
  &__bind {
    margin: .32rem .32rem 0;
    .bar {
      display: flex;
      padding: .48rem .32rem;
      justify-content: space-between;
      align-items: center;
      border-radius: .1067rem;
      border: 1px dashed #999;
      background-color: #fff;
      color: #000;
      font-size: .3733rem;
      font-weight: 400;
      line-height: .032rem;
    }
  }
  &__bind-card {
    padding: 0 .32rem;
  }
  &__subtitle {
    padding: .32rem .32rem 0;
    color: #000;
    margin-bottom: .24rem;
    font-size: .32rem;
    font-weight: 500;
    font-family: "system-ui";
    line-height: normal;
  }
  &__card {
    padding: .32rem;
    position: relative;
    padding-bottom: .4rem;
    .block {
      position: relative;
      overflow-y: scroll;
      padding-bottom: .2667rem;
      height: 100%;
      .card {
        margin-bottom: .32rem;
      }
    }
  }
  &__discount {
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: .2133rem .32rem;
    background-color: #fff;
    z-index: 10;
    .label {
      color: #222;
      font-size: .3733rem;
      font-weight: 700;
    }
    .price {
      color: #FA6338;
      text-align: right;
      font-size: .3733rem;
      font-weight: 700;
    }
  }
  &__feedback {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
}
</style>
