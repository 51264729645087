<template>
  <div class="bsg-form-container">
    <SForm
      ref="cardForm"
      :model="cardFormData"
    >
      <div class="bsg-form-container__hintgroup">
        <SFormItem
          :field="`cardNo`"
          :show-message="false"
        >
          <label
            for="cardNo"
            class="bsg-form-container__origin-label"
          >
            {{ isPwa ? language?.SHEIN_KEY_PWA_35110 : language?.SHEIN_KEY_H5_41706 }}
          </label>
          <input
            id="cardNo"
            v-model="cardFormData.cardNo"
            class="bsg-form-container__origin-input"
            type="text"
            :maxlength="25"
            placeholder="0000 0000 0000 0000"
            @input="cardNoBlur"
          />
        </SFormItem>
      </div>
      <div class="bsg-form-container__hintgroup bsg-form-container__mt12">
        <SFormItem
          :field="`cardPin`"
          :show-message="false"
        >
          <label
            for="cardPin"
            class="bsg-form-container__origin-label"
          >
            {{ isPwa ? language?.SHEIN_KEY_PWA_35111 : language?.SHEIN_KEY_H5_41707 }}
          </label>
          <input
            id="cardPin"
            v-model.trim="cardFormData.cardPin"
            class="bsg-form-container__origin-input"
            type="password"
            placeholder="0000"
            @input="filterNumeric"
          />
        </SFormItem>
      </div>
      <div
        v-if="isShowSwitch && isPwa"
        v-expose="swtichExpose()"
        class="bsg-form-container__mt12 bsg-form-container__swtich-container"
      >
        <span>{{ language?.SHEIN_KEY_PWA_35112 }}</span>
        <SCheckboxSwitch
          v-model="cardFormData.switchVal"
          v-tap="tapSwtichExpose()"
        />
      </div>
    </SForm>
    <SButton
      :type="['primary']"
      :loading="false"
      :disabled="!cardFormData.cardNo || !cardFormData.cardPin"
      class="bsg-form-container__btn"
      @click="submit"
    >
      {{ isPwa ? language?.SHEIN_KEY_PWA_35127 : language?.SHEIN_KEY_H5_41761 || 'Link card' }}
    </SButton>

    <!-- 二次确认弹框 -->
    <SDialog
      class="bsg-gift-sure__dialog"
      :visible="dialogVisible"
      append-to-body
      :show-close="false"
      :close-on-click-modal="false"
      @close="dialogVisible = false"
    >
      <template #title>
        {{ isPwa ? language?.SHEIN_KEY_PWA_35113 : language?.SHEIN_KEY_H5_41709 }}
      </template>
      <div class="bsg-form-container__dialog_text">
        {{ isPwa ? language?.SHEIN_KEY_PWA_35114 : language?.SHEIN_KEY_H5_41710 }}
      </div>
      <template #footer>
        <SButton
          :type="['H72PX', 'primary']"
          :width="'100%'"
          :loading="isPwa ? btnLoading : isConfirmLoading"
          @click="checkGiftCard"
        >
          {{ isPwa ? language?.SHEIN_KEY_PWA_35115 : language?.SHEIN_KEY_H5_41711 }}
        </SButton>
        <SButton
          :type="['H72PX']"
          :width="'100%'"
          class="bsg-form-container__dialog_cancel"
          @click="handleCancel"
        >
          {{ isPwa ? language?.SHEIN_KEY_PWA_34593 : language?.SHEIN_KEY_H5_39125 }}
        </SButton>
      </template>
    </SDialog>
  </div>
</template>

<script name="BBindGiftCard" setup lang="ts">
import { computed, ref, watch, inject } from 'vue'
import { SForm } from '@shein-aidc/sui-form/mobile'
import { SFormItem } from '@shein-aidc/sui-form-item/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import type { AS_BindGiftCard } from '../../../types/index'
import useApis from '../hooks/useApis'
import type { DS_BindGiftCard } from '../../../types'
import { SCheckboxSwitch } from '@shein-aidc/sui-checkbox-switch/mobile'

const props = defineProps<{
  language?: any
  sence?:any
  visible?: boolean
  terminalType?: any
  isShowSwitch?: boolean
  isConfirmLoading?: boolean
}>()

const isPwa = computed(() => {
  return props?.terminalType == 'pwa'
})
const {triggerNotice, vTap, vExpose} = isPwa.value ? inject('analysisInstance') as DS_BindGiftCard.AnalysisInstance : {}

const emit = defineEmits([
  'bind-card-cb',
  'handle-bind-card',
  'expose-confirm-model',
])

const { postBindGiftCardApi } = isPwa.value ? useApis() : {}
const btnLoading = ref(false)
const cardForm = ref<InstanceType<typeof SForm> | null>(null)
const dialogVisible = ref(false)
const cardFormData = ref<AS_BindGiftCard.CardFormData>({
  cardNo: '',
  cardPin: '',
  switchVal: true,
})
const pin = ref({
  pinInfo: '',
  errorTip: '',
})

watch(() => props.visible, (n) => {
  if (!n) {
    cardFormData.value = {
      cardNo: '',
      cardPin: '',
      switchVal: true,
    }
  }
})

const online = computed(() => {
  return typeof navigator !== 'undefined' ? navigator.onLine : true
})

const swtichExpose = () => {
  if(props?.sence == 'checkout') {
    return {
      id: 'expose_bind_card_swtich.comp_bind-gift-card',
      data: {
        switch_status: cardFormData.value?.switchVal ? 'on' : 'off',
      },
    }
  }
}

const tapSwtichExpose = () => {
  return {
    id: 'click_bind_card_swtich.comp_bind-gift-card',
    data: {
      switch_status: cardFormData.value?.switchVal ? 'off' : 'on',
    },
  }
}

const cardNoBlur =() => {
  cardFormData.value.cardNo = cardFormData.value?.cardNo
    ?.replace(/\s/g, '')
    // .replace(/(\w{4})/g, '$1 ')
    .match(/.{1,4}/g)?.join(' ') || ''
}
const submit = async function(){
  emit('expose-confirm-model')
  if (!online.value) {
    SToast(props.language?.SHEIN_KEY_PWA_31762)
    return
  }
  // if (!isLogin()) {
  //   this.goLogin()
  //   return
  // }
  let result = await cardForm.value?.validate()
  if(result.valid){
    if(isPwa.value) {
      pin.value.errorTip = ''
      const { cardNo = '', cardPin = '', switchVal } = cardFormData.value
      if((props?.isShowSwitch && !cardFormData.value?.switchVal) || (!props?.isShowSwitch && props?.sence == 'checkout')) {
        const card_no = cardNo.replace(/\s/g, '')
        emit('bind-card-cb', {
          card_no,
          card_pin: cardPin,
          isBindCard: props?.isShowSwitch ? switchVal : false,
        })

        triggerNotice && triggerNotice({
          id: 'click_card_confirm.comp_bind-gift-card',
          data: {
            use_type: 'used',
          },
        })
      } else {
        dialogVisible.value = true
        if(props?.sence == 'person') {
          triggerNotice && triggerNotice({
            id: 'expose_giftcard_link_check.comp_bind-gift-card',
          })
        }
        if(props?.sence == 'checkout') {
          triggerNotice && triggerNotice({
            id: 'click_card_confirm.comp_bind-gift-card',
            data: {
              use_type: 'bind',
            },
          })
        }
      }
    } else {
      pin.value.errorTip = ''
      dialogVisible.value = true
    }
  }
}

const checkGiftCard = async function(data) {
  if(isPwa.value) {
    try {
      const {reskId = null, validate_token = null } = data
      btnLoading.value = true
      const { cardNo = '', cardPin = '', switchVal } = cardFormData.value
      const card_no = cardNo.replace(/\s/g, '')

      let params: DS_BindGiftCard.BindApiParams = {
        card_no: card_no,
        card_pin: cardPin,
        scene: props?.sence,
      }
      reskId && (params.risk_id = reskId)
      validate_token && (params.validate_token = validate_token)
      postBindGiftCardApi && postBindGiftCardApi('/user-api/gift_card/bind', params).then((res) => {
        btnLoading.value = false
        dialogVisible.value = false
        emit('bind-card-cb', {
          resData: res,
          card_no,
          card_pin: cardPin,
          isBindCard: props?.isShowSwitch ? switchVal : false,
        })
        if(res.code == 0) {
          cardFormData.value = {
            cardNo: '',
            cardPin: '',
            switchVal: true,
          }
        }
      })
    } catch (error) {
      btnLoading.value = false
    }
  } else { //h5
    const { cardNo = '', cardPin = '' } = cardFormData.value
    const card_no = cardNo.replace(/\s/g, '')
    emit('handle-bind-card', {
      card_no,
      cardPin,
    })
  }
}

const handleCancel = () => {
  !props?.isConfirmLoading && (dialogVisible.value = false)
}

const filterNumeric = () => {
  cardFormData.value.cardPin = cardFormData.value?.cardPin?.replace(/[^\d]/g, '')
}

const closeSureModal = () => {
  dialogVisible.value = false
}

defineExpose({
  closeSureModal,
  checkGiftCard,
})

</script>
<style lang="less">
.bsg-form-container .S-Field__endblock{
  flex: 0;
}
.bsg-form-container .sui-form-item {
  margin-bottom: 0;
}
.bsg-form-container__dialog .sui-dialog__body {
  box-sizing: border-box;
}
.bsg-form-container {
  &__origin-input {
    display: block;
    width: 100%;
    padding: 5/37.5rem 12/37.5rem 12/37.5rem;
    border: none;
    line-height: .4533333333rem;
    font-size: 14px;
    color: #222;
  }
  &__origin-label {
    display: block;
    font-size: 14px;
    padding: 12/37.5rem 12/37.5rem 0;
  }
  &__hintgroup {
    border: 1px solid #E5E5E5;
    &_fileld&_block {
      display: block;
    }
    &_fileld&_block::after {
      display: none;
    }
    label {
      color: #767676 !important; /* stylelint-disable-line declaration-no-important */
    }
    input {
      &::-webkit-input-placeholder {
        color: #bbbbbb;
      }
      &::-moz-placeholder {
        color: #bbbbbb;
      }
      &:-ms-input-placeholder {
        color: #bbbbbb;
      }
    }
    .text-error {
      padding: 0 0.32rem;
      color: @sui_color_unusual;
      margin-top: 16/75rem;
    }
  }
  &__mt12 {
    margin-top: 12/37.5rem;
  }
  &__btn {
    width: 100%;
    height: 40/37.5rem;
    margin-top: 20/37.5rem;
    line-height: 1;
  }
  &__swtich-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.bsg-form-container__dialog_text {
  margin: 12/37.5rem 0 0;
  color: #000;
  text-align: center;
  font-family: "SF UI Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bsg-form-container__dialog_cancel {
  margin-top: 12/37.5rem;
}

</style>
