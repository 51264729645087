import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
export { default, default as BGiftCard } from './gift-card.vue'
import type { DS_GiftCard } from '../../types'
export type * from '../../types'

import { getFsDataRunner } from '../../common/dataSource'

export function getGiftCardFsDataRunner(sourceParams?: Partial<DS_GiftCard.SourceParams>) {
  return getFsDataRunner({ appConfigs: useAppConfigs(), ...sourceParams })
}
