import type { AS_GiftCard } from '../../../types'

const standard: AS_GiftCard.AnalysisFuncs = {
  // TODO
  'click_your_sa_event_name.comp_gift-card': ({ extraData }) => {
    return {
      ...extraData,
    }
  },
  // 'page_view.page_linked_giftcard_list': ({ extraData }) => {
  //   return {
  //     ...extraData,
  //     $options: {
  //       page_name: 'page_linked_giftcard_list',
  //     },
  //   }
  // },
  // pwa
}

export default standard
