<template>
  <div
    class="bsc-collapsible-text"
    @click="expanded = !expanded"
  >
    <slot name="left-icon"></slot>
    <span
      ref="collapsibleTextRef"
      class="bsc-collapsible-text__text"
      :class="{
        'bsc-collapsible-text__text_ellipsis': collapsible,
        'bsc-collapsible-text__text_hidden': !expanded
      }"
    >
      {{ text }}
    </span>
    <Icon
      v-if="collapsible"
      :name="expanded ? 'sui_icon_more_up_12px_2' : 'sui_icon_more_down_12px_2'"
      size="12px"
      color="#959595"
      is-responsive-name
    />
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
})

const collapsibleTextRef = ref(null)
const collapsible = ref(false)
const expanded = ref(false)

watch(
  () => [props.text, collapsibleTextRef.value],
  () => {
    if (!props.text || !collapsibleTextRef.value) return
    const { offsetWidth, scrollWidth } = collapsibleTextRef.value
    collapsible.value = offsetWidth < scrollWidth
  },
  {
    immediate: true,
  },
)
</script>
<style lang="less">
.bsc-collapsible-text {
  display: flex;
  line-height: 1.2;
  overflow: hidden;

  &__text {
    margin-left: .08rem;
    white-space: normal;
  }

  &__text_hidden {
    overflow: hidden;
    white-space: nowrap;
  }

  &__text_ellipsis {
    text-overflow: ellipsis;
  }
}
</style>
