<template>
  <div v-if="isNeedDrawer">
    <SDrawer
      v-expose="exposeFormOnGiftCardList()"
      :visible="visible"
      :show-close="true"
      append-to-body
      :close-on-click-modal="true"
      v-bind="drawerParams"
      @close-from-mask="handleClose"
    >
      <template #title>{{ isPwa ? language?.SHEIN_KEY_PWA_35126 : language?.SHEIN_KEY_H5_41760 }}</template>
      <template #closeIcon>
        <Icon
          name="sui_icon_close_16px_2"
          size="16px"
          @click="handleClose"
        />
      </template>
      <div class="bsb-gift-drawer-content">
        <GiftForm
          ref="giftFormRef"
          :language="language"
          :isShowSwitch="isShowSwitch"
          :visible="visible"
          :terminal-type="terminalType"
          :isConfirmLoading="isConfirmLoading"
          @handle-bind-card="handleBindCard"
          @expose-confirm-model="exposeConfirmModel"
          @bind-card-cb="bindCardCb"
        />
        <UseageProcess
          v-if="isShowStepText"
          :language="language"
          :terminal-type="terminalType"
        />
      </div>
    </SDrawer>
  </div>
  <div v-else>
    <GiftForm
      ref="giftFormRef"
      v-expose="exposeFormOnGiftCardList()"
      :language="language"
      :visible="visible"
      :terminal-type="terminalType"
      :isConfirmLoading="isConfirmLoading"
      @handle-bind-card="handleBindCard"
      @expose-confirm-model="exposeConfirmModel"
      @bind-card-cb="bindCardCb"
    />
    <UseageProcess
      v-if="isShowStepText"
      :language="language"
      :terminal-type="terminalType"
    />
  </div>
</template>

<script name="BBindGiftCard" setup lang="ts">
import type { AS_BindGiftCard, DS_BindGiftCard } from '../../types'
import GiftForm from './components/gift-form.vue'
import UseageProcess from './components/useage-process.vue'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { Icon } from '@shein-aidc/icon-vue3'
import { useAnalysis } from '../../common/analysisSource'
import { ref, defineExpose, provide, withDefaults } from 'vue'
import { computed } from 'vue'

interface GiftFormInstance {
  closeSureModal: () => void;
  checkGiftCard: () => void
}

const emit = defineEmits([
  'handle-close',
  'handle-bind-card',
  'expose-confirm-model',
  'bind-card-cb',
])

type propsType = {
  drawerParams?: any
  sence?: string
  isShowSwitch: boolean
  language?: any
  terminalType?: any
  isNeedDrawer?: boolean
  visible?: boolean
  isShowStepText?: boolean
  isConfirmLoading?: boolean
  fsData?: DS_BindGiftCard.FS_DATA
  dataSource?: DS_BindGiftCard.DataSource
  analysisSource?: AS_BindGiftCard.AnalysisSource
}

const props = withDefaults(defineProps<propsType>(), {
  sence: 'person',
  isNeedDrawer: true,
  terminalType: '',
  isShowStepText: true,
  isShowSwitch: false,
})

const isPwa = computed(() => {
  return props?.terminalType == 'pwa'
})

const analysisInstance = isPwa.value ? useAnalysis() : {}
provide('analysisInstance', analysisInstance)
const { vExpose } = isPwa.value ? analysisInstance : {}

const giftFormRef = ref<GiftFormInstance | null>(null)

const bindCardCb = (data) => {
  emit('bind-card-cb', data)
}
const exposeFormOnGiftCardList = () => {
  let obj = {}
  if(props?.sence == 'checkout' && isPwa.value) {
    obj = {
      id: 'expose_input_giftcard_pop.comp_bind-gift-card',
    }
  }
  return obj
}

const closeSureModal = () => {
  giftFormRef.value?.closeSureModal()
}
const handleClose = () => {
  emit('handle-close')
}

const handleBindCard = (data) => {
  emit('handle-bind-card', data)
}

const exposeConfirmModel = () => {
  emit('expose-confirm-model')
}

const checkGiftCard = (data) => {
  if (giftFormRef.value) {
    giftFormRef.value?.checkGiftCard(data)
  }
}

defineExpose({
  closeSureModal,
  checkGiftCard,
})
</script>
<style lang="less">
.bsb-gift-drawer-content {
  padding: 8/37.5rem 12/37.5rem;
}
</style>
