<template>
  <div class="bsc-gc-tablist">
    <STab
      v-if="hasCardList && getTabList.length > 0 && !!language"
      v-model="tabIndex"
      @change="(v) => handleTabIndex(v.val)"
    >
      <STabItem
        v-for="(tabName, index) in getTabList"
        :id="index"
        :key="tabName"
      >
        {{ tabName }}
      </STabItem>
    </STab>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, computed, watch, type Ref } from 'vue'
import { STab } from '@shein-aidc/sui-tab/mobile'
import { STabItem } from '@shein-aidc/sui-tab-item/mobile'
import { type C_GiftCard } from '../../../types'

const language: C_GiftCard.language = inject('language')!
const cardData: Ref<C_GiftCard.CardData> = inject('cardData')!

const tabIndex = ref<number>(0)
const hasCardList = ref<boolean>(true)

const getTabList = computed(() => ([language?.value?.SHEIN_KEY_PWA_22586, language?.value?.SHEIN_KEY_PWA_35099]))

const emits = defineEmits(['updateCardList', 'updateTabIndex'])

const handleTabIndex = (index: number) => {
  tabIndex.value = index
}

const getCardList = (index, data) => {
  const { available_card_list = [], unavailable_card_list = [] } = data || {}
  hasCardList.value = !(available_card_list.length === 0 && unavailable_card_list.length === 0)
  return index === 0 ? available_card_list : unavailable_card_list
}

watch(() => [tabIndex.value, cardData.value], (v) => {
  const [index, data] = v
  emits('updateCardList', getCardList(index, data))
  emits('updateTabIndex', index)
}, { immediate: true })

defineExpose({
  handleTabIndex,
})
</script>

<style lang="less">
.bsc-gc-tablist {
  &__empty-list {
    margin-top: 1.7333rem;
    text-align: center;
    .img {
      width: 2rem;
    }
    .text {
      margin-top: .5333rem;
      color: #222;
      text-align: center;
      font-size: .3733rem;
      font-weight: 400;
    }
  }
}
</style>
