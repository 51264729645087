import { getLangs } from '@shein-aidc/bs-sdk-libs-manager'
import type { DS_GiftCard } from '../../../types'

// TODO write your apis

export const getGiftCardListApi: DS_GiftCard.APIS_THIS['getGiftCardListApi'] = async function (url, data ) {
  const res = await this.$schttp<Record<string, object>>({
    url,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  console.log('🐬🐬🐬 es: res api', res)
  return res.data
}

export const getLangApi: DS_GiftCard.APIS_THIS['getLangApi'] = async function (langsMap) {
  const result = await getLangs(langsMap, this.$schttp)
  return result
}
