import * as standard from './sources/standard'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_GiftCard } from '../../types'

export function getSource({dataSource = {}, appConfigs}:DS_GiftCard.SourceParams): DS_GiftCard.APIS {
  return organizeDataSource<DS_GiftCard.APIS, DS_GiftCard.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_GiftCard.FS_SourceParams) {
  const { getGiftCardListApi, getLangApi } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_GiftCard.FS_APIS, DS_GiftCard.FS_DATA>({
    getGiftCardListApi,
    getLangApi,

  })
}
